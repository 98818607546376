<template>
	<Fragment>
		<div class="container">
			<div class="body-contents">
				<h2>추가/연장주문</h2>
				<ol class="stepper type2">
					<li class="step on">
						<a href="#" title="step01">
							<i>1</i>
							<span>서비스 및 상품 선택</span>
						</a>
					</li>
					<li class="step on">
						<a href="#" title="step02">
							<i>2</i>
							<span>약관 동의 및 결제</span>
						</a>
					</li>
					<li class="step on">
						<a href="#" title="step03">
							<i>3</i>
							<span>주문 완료</span>
						</a>
					</li>
				</ol>
				<div class="section-wrap gap64 fluid">
					<div class="section">
						<div class="contents text-center">
							<!-- 신규 주문과 문구 다름  -->
							<p class="text-large">다우오피스 멤버십 결제가 완료되었습니다.</p>
							<img class="guide" src="@/assets/img/dada.png" />
							<p class="text-medium mb-16">
								구매하신 서비스 및 상품이 적용되기까지 <em class="primary">약 10분~20분</em> 정도 소요됩니다.<br />
								구매 상품이 적용되지 않았을 경우 스피키 챗봇 상담 혹은<br />
								영업담당자에게 연락 주시기 바랍니다.<br />
							</p>
							<p class="description">
								상담문의 : 1577-3019<br />
								상담시간 : 09:00 ~ 17:30 (점심 12:00~13:00)<br />
								주말 / 공휴일 휴무<br />
							</p>
						</div>
					</div>
					<div class="session" v-if="usePayMethod">
						<div class="contents">
							<payment-info></payment-info>
						</div>
					</div>
					<div class="session">
						<h3 class="title">개설 정보 안내</h3>
						<div class="contents">
							<company-info></company-info>
						</div>
					</div>
					<div class="session">
						<div class="contents">
							<order-info></order-info>
						</div>
					</div>
					<div class="btn-area">
						<button class="line large" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>
<script>
import PaymentInfo from '@/components/order/PaymentInfoResult.vue';
import CompanyInfo from '@/components/order/CompanyInfoResult.vue';
import OrderInfo from '@/components/order/OrderInfo.vue';
export default {
	created() {
		window.scrollTo(0, 0);
	},
	computed: {
		usePayMethod() {
			return this.$store.getters.getNewMembershipChargeType === 'FREE' &&
				this.$store.getters.getAddExtensionPrices.finalPayPrice === 0
				? false
				: true;
		},
	},
	data() {
		return {
			stepBtnInfo: {
				next: '완료',
				nextLink: '/user/myStore/MembershipStatus',
				prevLink: '/order/Purchase',
			},
		};
	},
	components: {
		OrderInfo,
		CompanyInfo,
		PaymentInfo,
	},
	methods: {
		complete() {
			this.$router.push('/user/myStore/MembershipStatus');
		},
	},
};
</script>

<style scoped>
@import '../../assets/css/order.css';
</style>
