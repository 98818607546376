<template>
	<div class="form_data">
		<div class="com_form">
			<div class="wrap_row wrap_data_mini">
				<div class="row">
					<label class="form_tit cell"><span class="txt">상호명</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.corporateName }}</span>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">사업자등록번호</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.businessLicenseNum }}</span>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">사업자등록증</span></label>
					<div class="form_data cell">
						<span class="txt"
							><template
								v-if="
									this.$store.getters.getBusinessRegistration && this.$store.getters.getBusinessRegistration.length > 0
								"
							>
								첨부</template
							><template v-else> 미첨부</template></span
						>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">대표자명</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.ceoName }}</span>
					</div>
				</div>
				<div class="row" v-if="this.$store.getters.getNewSiteInfo.companyInfo.directPhone">
					<label class="form_tit cell"><span class="txt">대표전화</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.directPhone }}</span>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">사업장 주소</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.companyAddress }}</span>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">산업분류</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.industrySector }}</span>
					</div>
				</div>
				<div class="row" v-if="this.$store.getters.getNewSiteInfo.companyInfo.businessCondition">
					<label class="form_tit cell"><span class="txt">업태</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.businessCondition }}</span>
					</div>
				</div>
				<div class="row" v-if="this.$store.getters.getNewSiteInfo.companyInfo.businessType">
					<label class="form_tit cell"><span class="txt">종목</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.businessType }}</span>
					</div>
				</div>
				<div class="row" v-if="this.$store.getters.getNewSiteInfo.companyInfo.issuedBillName">
					<label class="form_tit cell"><span class="txt">세금계산서 담당자명</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.issuedBillName }}</span>
					</div>
				</div>
				<div class="row" v-if="this.$store.getters.getNewSiteInfo.companyInfo.issuedBillEmail">
					<label class="form_tit cell"><span class="txt">세금계산서 담당자 이메일</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getNewSiteInfo.companyInfo.issuedBillEmail }}</span>
					</div>
				</div>
				<div class="row">
					<label class="form_tit cell"><span class="txt">세금계산서</span></label>
					<div class="form_data cell">
						<span class="txt">{{ this.$store.getters.getPayMethodInfo.issuedBill ? '발행' : '미발행' }} </span>
					</div>
				</div>
				<template v-for="(item, index) in this.$store.getters.getNewSiteInfo.companyInfo.addonItem">
					<div class="row" :key="`B-${index}`">
						<label class="form_tit cell"
							><span class="txt">{{ item.name }} 관리자 ID</span></label
						>
						<div class="form_data cell">
							<span class="txt"> {{ item.masterId }}</span>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style></style>
